import React, { useContext } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "components/layout";
import PageHeader from "components/PageHeader";
import { LocaleContext } from "context/locale-context";
import { rhythm } from "utils/typography";
import Card from "components/card";
import LocalizedLink from "components/localizedLink";
import { FaAngleLeft } from "react-icons/fa";

const TechAdvocacyArchive = ({ data }) => {
  const posts = data.posts.edges;
  const locale = useContext(LocaleContext);
  const isRTL = locale === "ar";
  const desc =
    "Hundreds of thousands of images, videos and text have been taken down by social media platform that are related to Syria's history and human rights situation. ";

  console.log('posts', posts)
  return (
    <Layout className={locale}>
      <PageHeader title="Content advocacy" desc={desc} />

      <div
        css={{
          maxWidth: rhythm(39.5),
          margin: "0 auto",
          marginTop: rhythm(4.3),
          direction: isRTL ? "rtl" : "ltr"
        }}
      >
        <LocalizedLink
          to="/lost-found"
          css={{ position: "relative", fontSize: "85%" }}
        >
          <span
            css={{
              paddingRight: isRTL ? "auto" : rhythm(0.4),
              paddingLeft: isRTL ? rhythm(0.5) : "auto",
              position: "absolute",
              top: rhythm(-0.1),
              left: rhythm(-0.8)
            }}
          >
            <FaAngleLeft />
          </span>
          <span css={{ fontWeight: "bold" }}>Go back to Advocacy page</span>
        </LocalizedLink>
        <h2>Updates</h2>
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gridRowGap: rhythm(6.2),
            gridColumnGap: rhythm(1),
            marginTop: rhythm(4.2),
            marginBottom: rhythm(4.2),
            direction: isRTL ? "rtl" : "ltr"
          }}
        >
          {posts.map(({ node }) => (
            <Card node={node} key={node.fields.slug} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

TechAdvocacyArchive.propTypes = {
  data: PropTypes.object
};

export default TechAdvocacyArchive;

export const pageQuery = graphql`
  query TechAdvocacyArchive($locale: String!) {
    posts: allMdx(
      filter: {
        fields: { locale: { eq: $locale } }
        fileAbsolutePath: { regex: "/content/lost-found/" }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            desc
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 260
                  placeholder: BLURRED
                )
              }
            }
          }
          body
          fields {
            slug
          }
        }
      }
    }
  }
`;
